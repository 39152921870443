<template>
  <transition name="slide-down-fade">
  <div class="modal" v-show="showModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" @click="closeModal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="col-md">
            <h1 class="modal-title d-inline-block">Callback -testaus</h1> <a class="" href="#" @click.prevent="showHelp = !showHelp" >Ohje</a>
            <transition name="slide-fade-helper">
            <div v-if="showHelp">
              <p>Voit testata rajapinnan callback (<code>partnerCallback</code>) -ominaisuutta.</p>
              <p>Lomake muodostaa rajapintakuvauksen mukaisen callback-pyynnön ja lähettää sen annettuun osoitteeseen.</p>
              <p>Pyyntö lähetetään suoraan selaimestasi, joten osoitteet ilman validia SSL-sertifikaattia eivät välttämättä toimi. Pyyntöjen tekeminen ei myöskään onnistu palvelimille, jotka eivät hyväksy Visma Pay Partnerportaalia HTTP-kyselyjen lähteeksi (CORS). Lomake tulostaa kuitenkin pyynnön JSON-muodossa ja voit kopioida sen.</p>
            <hr class="mt-10">
            </div>
            </transition>
          </div>
          <form role="form" @submit="createCallback">
            <div class="col-md">
              <div class="form-group">
                <label for="url">Osoite</label>
                <input type="text" class="form-control" v-model="url" name="url" placeholder="https://osoite.com/callback" />
              </div>
            </div>
            <div class="col-md">
              <div class="form-group">
                <label for="callbackSecret">callbackSecret</label>
                <input type="text" class="form-control" v-model="callbackSecret" name="callbackSecret " placeholder="callbackSecret" />
                <small>Jos <code>callbackSecret</code>-kenttää ei ole annettu, <code>authCode</code>-kenttää ei palauteta.</small>
              </div>
            </div>
            <div class="col-md">
              <div class="form-group">
                <label for="id">id</label>
                <input type="text" class="form-control" v-model="id" name="id" placeholder="id" />
              </div>
            </div>
            <div class="col-md">
              <div class="form-group">
                <label for="boardingStatus">boardingStatus</label>
                <select class="form-control" name="boardingStatus" v-model="boardingStatus" @change="checkMerchantCreated()">
                  <option v-for="status in boardingStatuses" v-bind:key="status">{{status}}</option>  
                </select>
              </div>
            </div>
            <div class="col-md">
              <div class="form-group">
                <label for="merchantCreated">merchantCreated</label>
                <input v-if="boardingStatus !== 'completed'" class="form-control" name="merchantCreated" v-model="merchantCreated" disabled>
                <select v-else class="form-control" name="merchantCreated" v-model="merchantCreated" >
                  <option value="0">0</option>  
                  <option value="1" v-if="boardingStatus === 'completed'">1</option>  
                </select>
              </div>
            </div>
            <div v-if="merchantCreated == 1 && boardingStatus === 'completed'">
              <div class="col-md">
                <div class="form-group">
                  <label for="channelId">channelId</label>
                  <input type="number" class="form-control" name="channelId" v-model="channelId" />
                </div>
              </div>
              <div class="col-md">
                <div class="form-group">
                  <label for="subMerchantId">subMerchantId</label>
                  <input type="number" class="form-control" name="subMerchantId" v-model="subMerchantId" />
                </div>
              </div>
            </div>
            <div class="col-md mt-10">
              <button 
                type="submit" 
                :class="[inProgress === true ? 'disabled': '', 'btn w-100 btn-primary']">
                  Lähetä
              </button>
            </div>
            <div class="col-md mt-10" v-if="sentRequestRaw !== ''">
              <p>Pyyntö raakamuodossa <a href="#" @click="copyToClipboard">Kopioi</a></p>
<pre style="overflow-x:auto">
{{ sentRequestRaw }}
</pre>
              <p v-if="returnMessage">{{ returnMessage }}</p>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  </transition>
</template>
<script nonce="2726c7f26c">

export default {
  name: 'CallBackTester',
  data() {
    return {
      showHelp: false,
      inProgress: false,
      callbackSecret: '',
      url: '',
      id: '',
      boardingStatuses: [
        'pending',
        'waitingForSignatures',
        'signingError',
        'signingCancelled',
        'signed',
        'completed',
        'rejected'
      ],
      boardingStatus: 'pending',
      merchantCreated: 0,
      channelId: 0,
      subMerchantId: 0,
      sentRequestRaw: '',
      returnMessage: ''
    }
  },
  computed: {
    showModal() {
      return this.$store.state.showModal['callbackTester'] === true;
    }
  },
  methods: {
    closeModal() {
      this.$store.state.showModal['callbackTester'] = false;
    },
    checkMerchantCreated()
    {
      if(this.boardingStatus === 'completed')
        this.merchantCreated = 1;
      else
        this.merchantCreated = 0;
    },
    bufToHex(buff) {
      return [... new Uint8Array(buff)].map(x => x.toString(16).padStart(2, '0')).join('');
    },
    async generateAuthCode(authCodeInput, callbackSecret) {
      // hmac sha-256 function using SubtleCrypto to replace unavailable node Crypto on front
      // Vue cannot bundle native node Crypto without explicit 'crypto-js' import in package.json
      const subtle = window.crypto.subtle;
      const enc = new TextEncoder('utf-8');
      const algorithm = {
        name: "HMAC",
        hash: {
          name: "SHA-256"
        }
      };

      const secret = await subtle.importKey('raw', enc.encode(callbackSecret), algorithm, true, ['sign']);
      const authCode = await subtle.sign(algorithm, secret, enc.encode(authCodeInput));
      const signature = this.bufToHex(authCode).toUpperCase();

      return signature;
    },
    async createCallback(event)
    {
      event.preventDefault();

      this.inProgress = true;
      this.returnMessage = 'Pyyntöä lähetetään...';

      let authCode = '';
      if(this.callbackSecret !== '')
      {
        let authCodeInput = this.id + '|' + this.boardingStatus + '|' + this.merchantCreated;

        if(this.merchantCreated == 1)
          authCodeInput += '|' + this.channelId + '|' + this.subMerchantId;

        authCode = await this.generateAuthCode(authCodeInput, this.callbackSecret);
      }

      const params = {
        callbackTimestamp: Date.now(),
        boardingItem: {
          id: this.id,
          boardingStatus: this.boardingStatus,
          merchantCreated: this.merchantCreated
        }
      }

      if(this.merchantCreated == 1)
      {
        params.boardingItem['channelId'] = Number(this.channelId);
        params.boardingItem['subMerchantId'] = Number(this.subMerchantId);
      }

      if(authCode !== '')
        params.boardingItem['authCode'] = authCode;

      this.sentRequestRaw = JSON.stringify(params, null, 4);

       const requestOptions = {
        method: "POST",
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(params),
        credentials: 'omit'
      }

      try 
      {
        await fetch(this.url, requestOptions);

        this.returnMessage = 'Pyyntö lähetetty';
      }
      catch(e)
      {
        this.returnMessage = 'Yhteysvirhe';
      }

      this.inProgress = false;
    },
    copyToClipboard(event)
    {
      event.preventDefault();
      navigator.clipboard.writeText(this.sentRequestRaw);
    }
  }
}
</script>

