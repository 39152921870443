<template>
  <div>
    <CreatePartner v-if="isAdmin" />
    <CallBackTester />
    <header class="navbar navbar-default only-one-item" role="navigation">
      <div class="navbar-header">
        <div class="navbar-brand">
          <a href="#" title="Visma Pay">Visma Pay - <small>Partner Portal</small></a>
        </div>
      </div>
      <nav class="collapse navbar-collapse" aria-lable="Menu">
        <ul class="nav navbar-nav nav-tabs navbar-right first-level context-selector">
          <li 
            :class="['dropdown user-dropdown icon', { 'open': visible }]" 
          >
            <a class="dropdown-toggle button-context" href="#" @click.prevent="visible = !visible">
              <span class="vismaicon vismaicon-menu vismaicon-user"></span>
              <small class="selectedContext" id=""></small>
              <span class="caret"></span>
            </a>
            <transition name="slide-down-fade">
              <ul 
                class="dropdown-menu company-selection" 
                role="menu" 
                v-show="visible"
              >
                <li class="user-details-area clear" role="menuitem">
                  <div>
                    <div class="">
                      <span>{{ getEmail }}</span><br>
                      <span class="text-disabled">{{ getPartnerCode }}</span>
                    </div>
                  </div>
                </li>
                <li class="divider"></li>
                <li role="menuitem">
                  <a class="nav-menu-link" href="#" @click.prevent="showModal('callbackTester')" role="menuitem" ><span class="vismaicon vismaicon-web"></span> Rajapinnan callback -testaus</a>
                </li>
                <li v-if="isAdmin" role="menuitem">
                  <a class="nav-menu-link" href="#" @click.prevent="showModal('createPartner')" role="menuitem"><span class="vismaicon vismaicon-add-user"></span> Luo Partner -tunnus</a>
                </li>
                <li class="divider divider-strong"></li>
                <li class="context-footer company-selection-footer clear" role="menuitem">
                  <a @click="logOut" class="log-out-link">Kirjaudu ulos</a>
                </li>
              </ul>
            </transition>
          </li>
        </ul>
        
      </nav>
      <div v-if="visible" class="outside" @click="visible = false"></div>
    </header>
  </div>
</template>
<script>
import { signOut } from 'aws-amplify/auth';
import CreatePartner from './CreatePartner.vue'
import CallBackTester from './CallBackTester.vue'

export default {
  name: 'NavBar',
  components: {
    CreatePartner,
    CallBackTester
  },
  data() {
    return {
      visible: false
    }
  },
  computed: {
    getEmail() {
      return this.$store.state.user.attributes.email;
    },
    getPartnerCode() {
      return this.$store.state.user.attributes['custom:partnerCode'];
    },
    isAdmin() {
      return this.$store.state.user.attributes['custom:isAdmin'] && parseInt(this.$store.state.user.attributes['custom:isAdmin']) === 1;
    }
  },
  methods: {
    logOut: async function(event) {
      event.preventDefault();
      await signOut();
    },
    showModal(modalName) {
      this.$store.state.showModal[modalName] = true;
      this.visible = false;
    }
  }
}

</script>
