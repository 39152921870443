<template>
  <div class="container" style="padding-top:100px">
    <NavBar />
    <div v-if="!partnerCodeSet" class="row mb-30">
      <div class="col-md">
        <div  class="alert alert-danger">Partner-koodia ei ole asetettu. Ota yhteys asiakaspalveluumme.</div>
      </div>
    </div>
    <div class="row mb-30">
      <div class="col-md">
        <StartBoarding />
      </div>
    </div>
    <div class="row">
    </div>
    <div class="row">
      <div class="col-md">
        <Boardings />
      </div>
    </div>
    <div class="row">
      <div class="col-md-1">
      </div>
    </div>
  </div>
</template>
<script>
import NavBar from './NavBar.vue'
import Boardings from './Boardings.vue'
import StartBoarding from './StartBoarding.vue'

export default {
  name: 'MainPage',
  components: {
    NavBar,
    Boardings,
    StartBoarding
  },
  computed: {
    partnerCodeSet() {
      return this.$store.state.user.attributes['custom:partnerCode'];
    }
  }
}
</script>