import { createApp } from 'vue'
import { createStore } from 'vuex'
import App from './App.vue'
import mitt from 'mitt'
import { Amplify } from 'aws-amplify'
import { fetchUserAttributes } from 'aws-amplify/auth'
import '@aws-amplify/ui-vue'
import aws_exports from './aws-exports'
import './assets/main.css'

const emitter = mitt()

Amplify.configure(aws_exports)

export const store = createStore({
	state: {
		user: {
			attributes: {},
			attributesLoaded: false
		},
		showModal: {
			createPartner: false,
			callbackTester: false
		}
	},
	mutations: {
		setAttributes(state, payload) {
			state.user.attributes = payload
		},
		setAttributesLoaded(state, payload) {
			state.user.attributesLoaded = payload
		}
	},
	actions: {
		async getAttributes({ commit }) {
			await fetchUserAttributes().then(attributes => {
				commit('setAttributes', attributes)
				commit('setAttributesLoaded', true)
			});
		},
		removeAttributes({ commit }) {
			commit('setAttributes', {});
			commit('setAttributesLoaded', false);
		}
	}
});

const app = createApp(App)
app.config.globalProperties.emitter = emitter
app.use(store)
app.mount('#app')