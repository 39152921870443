<template>
  <div>
    <div class="row">
      <div class="col-md-6">
        <h1><span class="vismaicon vismaicon-note-filled disabled"></span> Luodut sopimukset <span
            v-if="boardings.length > 0" class="badge badge-info">{{ boardings.length }} kpl</span></h1>
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="offset-md-2 col-md-4">
            <select :disabled="boardings.length === 0" :class="getCssClass(this.boardingStatus)" v-model="boardingStatus"
              @change="sortKey = 'createdAt'" class="rows-per-page-select float-right mb-10">
              <option class="text-std" value="">Näytä kaikki tilat</option>
              <option :class="getCssClass('pending')" value="pending">
                {{ translateStatus('pending') }} ({{ this.statusCounts.pending }})
              </option>
              <option :class="getCssClass('waitingForSignatures')" value="waitingForSignatures">
                {{ translateStatus('waitingForSignatures') }} ({{ this.statusCounts.waitingForSignatures }})
              </option>
              <option :class="getCssClass('signed')" value="signed">
                {{ translateStatus('signed') }} ({{ this.statusCounts.signed }})
              </option>
              <option :class="getCssClass('completed')" value="completed">
                {{ translateStatus('completed') }} ({{ this.statusCounts.completed }})
              </option>
              <option :class="getCssClass('signingError')" value="signingError">
                {{ translateStatus('signingError') }} ({{ this.statusCounts.signingError }})
              </option>
              <option :class="getCssClass('signingCancelled')" value="signingCancelled">
                {{ translateStatus('signingCancelled') }} ({{ this.statusCounts.signingCancelled }})
              </option>
              <option :class="getCssClass('rejected')" value="rejected">
                {{ translateStatus('rejected') }} ({{ this.statusCounts.rejected }})
              </option>
            </select>
          </div>
          <div class="col-md-6">
            <div class="form-group search-group">
              <label for="search" class="sr-only">Search</label>
              <input id="search" class="form-control" type="search" :disabled="boardings.length === 0"
                placeholder="Hae Y-tunnuksella..." aria-label="searchBlock" v-model="searchTerm"
                :class="{ 'focus': searchTerm !== '' }">
              <span
                :class="['icon-search vismaicon vismaicon-sm vismaicon-search', { 'disabled': searchTerm === '' }]"></span>
              <span class="clear-search">Clear</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loading === true" class="lds-ripple">
      <div></div>
      <div></div>
    </div>
    <div>
      <transition name="slide-fade">
        <div class="table-responsive" v-if="boardings.length > 0">
          <table class="table">
            <thead>
              <tr>
                <th style="width: 15%" class="cursor-pointer" @click="sortKey = 'createdAt', sortDesc = !sortDesc">Luotu
                  <span :class="[
                    'vismaicon vismaicon-sm vismaicon-arrow-up-circle',
                    { 'vismaicon vismaicon-filter': sortKey !== 'createdAt' },
                    { 'disabled': sortKey !== 'createdAt' },
                    { 'vismaicon-arrow-down-circle': sortDesc }
                  ]">
                  </span>
                </th>
                <th style="width: 10%">Y-tunnus </th>
                <th style="width: 15%">Yrityksen nimi</th>
                <th v-if="boardingStatus !== 'pending'" style="width: 20%">Tila</th>
                <th v-else style="width: 20%" class="cursor-pointer"
                  @click="sortKey = 'percentage', sortDesc = !sortDesc">
                  Tila
                  <span :class="[
                    'vismaicon vismaicon-sm vismaicon-arrow-up-circle',
                    { 'vismaicon vismaicon-filter': sortKey !== 'percentage' },
                    { 'disabled': sortKey !== 'percentage' },
                    { 'vismaicon-arrow-down-circle': sortDesc }
                  ]">
                  </span>
                </th>
                <th style="width: 18%">Paketti</th>
                <th style="width: 17%">Partneri</th>
                <th style="width: 5%">Linkki</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="boarding of sortedBoardings" v-bind:key="boarding.id">
                <td>
                  <span>{{ formatDate(boarding.createdAt)[0] }}</span>
                  <small v-if="formatDate(boarding.createdAt)[1]"> {{ formatDate(boarding.createdAt)[1] }}</small>
                </td>
                <td>{{ boarding.vatNumber }}</td>
                <td>{{ boarding.formData.companyName }}</td>
                <td :class="getCssClass(boarding.boardingStatus)">
                  <span>{{ translateStatus(boarding.boardingStatus) }} </span>
                  <CompletedPercentage v-if="boarding.boardingStatus === 'pending'"
                    :data="getLength(boarding.formData)" />
                </td>
                <td>
                  <span>{{ boarding.pricePackage.charAt(0).toUpperCase() + boarding.pricePackage.slice(1) }}</span> <span
                    class="badge opa-09 no-wrap" v-if="boarding.discountCode"><small>{{
                      boarding.discountCode.split('_')[0] }}</small></span>
                </td>
                <td>
                  {{ boarding.partnerMetaData.email ? boarding.partnerMetaData.email : '' }}
                </td>
                <td><a target="_blank" rel="noopener" :href="signUpUrl + boarding.id">Avaa</a></td>
              </tr>
            </tbody>
          </table>
        </div>
      </transition>
      <div class="row" v-if="boardings.length > 0">
        <div class="col-md text-right paginator mb-10">
          Näytä sivulla
          <select class="rows-per-page-select" v-model="rowsPerPage" @change="setRowsPerPage($event.target.value)">
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
          <template v-if="totalPages > 1">
            <a href="#" class="ml-15" @click="prevPage($event)"><span
                :class="['vismaicon vismaicon-arrow-left-circle', { 'disabled': currentPage === 1 }]"></span></a>
            <span> {{ currentPage }} / {{ totalPages }} </span>
            <a href="#" @click="nextPage($event)"><span
                :class="['vismaicon vismaicon-arrow-right-circle', { 'disabled': currentPage === totalPages }]"></span></a>
          </template>
        </div>
      </div>
      <div v-if="loading === false && boardings.length === 0 && !getBoardingsError">
        Ei sopimuksia
      </div>
      <div v-else-if="getBoardingsError">
        <div class="alert alert-danger">Virhe haettaessa sopimuksia</div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'aws-amplify/api';
import { urls } from '../config';
import CompletedPercentage from './CompletedPercentage.vue';

const translate = {
  states: {
    'pending': 'Kesken',
    'completed': 'Valmis',
    'signed': 'Allekirjoitettu',
    'signingError': 'Järjestelmävirhe',
    'signingCancelled': 'Allekirjoitus peruttu',
    'rejected': 'Hylätty',
    'waitingForSignatures': 'Odottaa allekirjoitusta',
  },
  t(key) {
    return this.states[key] ? this.states[key] : key
  }
};

let lastSortKey = 'createdAt';
let lastSortDesc = true;


export default {
  name: 'Boardings',
  components: {
    CompletedPercentage
  },
  data() {
    return {
      loading: true,
      boardings: [],
      sortKey: lastSortKey,
      sortDesc: lastSortDesc,
      signUpUrl: urls.signUpUrl,
      searchTerm: '',
      currentPage: 1,
      totalPages: 1,
      rowsPerPage: 20,
      boardingStatus: '',
      completedPercentage: [],
      getBoardingsError: false,
      statusCounts: {
        pending: 0,
        completed: 0,
        signed: 0,
        signingError: 0,
        signingCancelled: 0,
        rejected: 0,
        waitingForSignatures: 0
      }
    }
  },
  created: async function () {

    try {
      const getBoardings = get({
        apiName: 'partnerportalapi',
        path: '/boardings',
        options: {}
      });
      const { body } = await getBoardings.response;
      const json = await body.json();

      this.boardings = json.boardings;
      this.getStatusCounts(this.boardings);

    } catch (e) {
      this.getBoardingsError = true;
    }

    this.loading = false;

    this.emitter.on('newBoardingId', async (id) => {
      const requestOptions = {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      };

      const response = await fetch(urls.boardingUrl + id, requestOptions);
      const data = await response.json();

      data.partnerMetaData = {
        email: this.$store.state.user.attributes.email
      }

      this.boardings.push(data);
      this.getStatusCounts(this.boardings);
    })
  },
  methods: {
    sortBoardings(boardings, key, sortDesc, searchTerm, currentPage, rowsPerPage, status) {

      if (lastSortKey !== key) {
        this.sortDesc = true;
        sortDesc = true;
      }
      lastSortKey = key;

      const compare = (a, b) => {
        let keyA = key === 'percentage' ? this.getLength(a['formData']) : a[key];
        let keyB = key === 'percentage' ? this.getLength(b['formData']) : b[key];

        if (keyA < keyB)
          return sortDesc ? 1 : -1;
        else if (keyA > keyB)
          return sortDesc ? -1 : 1;
        else
          return 0;
      }

      boardings = boardings.sort(compare);

      if (searchTerm !== '') {
        boardings = boardings.filter((boarding) => {
          return boarding.vatNumber.replace('-', '').startsWith(searchTerm.replace('-', ''));
        });
      }

      if (status !== '') {
        boardings = boardings.filter((boarding) => {
          return boarding.boardingStatus === status;
        });
      }

      this.totalPages = Math.ceil(boardings.length / rowsPerPage);

      const start = (currentPage - 1) * rowsPerPage;
      const end = start + rowsPerPage;

      return boardings.slice(start, end);
    },
    formatDate(date) {
      date = new Date(date).toLocaleString("fi-FI", {
        timeZone: "Europe/Helsinki",
        day: '2-digit',
        month: '2-digit',
        year: "numeric",
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      });

      const datePieces = date.split(' klo ');
      return datePieces;
    },
    nextPage(event) {
      event.preventDefault();
      if (this.currentPage !== this.totalPages && this.totalPages > 0)
        this.currentPage++;
    },
    prevPage(event) {
      event.preventDefault();
      if (this.currentPage > 1)
        this.currentPage--;
    },
    setRowsPerPage(val) {
      this.currentPage = 1;
      this.rowsPerPage = val;
    },
    translateStatus(key) {
      return translate.t(key);
    },
    getCssClass(status) {
      const classes = {
        'completed': 'text-green',
        'pending': 'text-orange',
        'signed': 'text-blue',
        'waitingForSignatures': 'text-blueish',
        'signingError': 'text-redish',
        'signingCancelled': 'text-red',
        'rejected': 'text-red'
      };

      return classes[status] ? classes[status] : ''
    },
    getStatusCounts(boardings) {

      Object.keys(this.statusCounts).forEach(key => this.statusCounts[key] = 0);
      boardings.forEach(b => this.statusCounts[b.boardingStatus]++);
    },
    getLength(formData) {
      return Object.keys(formData).length;
    }
  },
  computed: {
    sortedBoardings() {
      return this.sortBoardings(this.boardings, this.sortKey, this.sortDesc, this.searchTerm, this.currentPage, parseInt(this.rowsPerPage), this.boardingStatus)
    }
  }
}
</script>