<template>
  <div class="d-inline completed-percetage" v-bind:title="title">
    <small :class="['text-disabled', cssClass]" >{{ progressBulls }}</small>
  </div>
</template>

<script>
  export default {
    name: 'CompletedPercentage',
    props: ['data'],
    data() {
      return {
        title: '',
        progressBulls: '',
        cssClass: ''
      } 
    },
    created() {
      const filledPercentage = this.$props.data;
      const fullCount = 30;
      const completedPercentage = Math.round(filledPercentage/fullCount * 100);

      if(completedPercentage >= 10 && completedPercentage < 25) {
        this.title = 'Täytetty alle 25%'
        this.progressBulls = '•'
        this.cssClass = 'text-red'
      }
      else if(completedPercentage >= 25 && completedPercentage < 50) {
        this.title = 'Täytetty noin 40%'
        this.progressBulls = '••'
        this.cssClass = 'text-orange'
      }
      else if(completedPercentage >= 50 && completedPercentage < 75) {
        this.title = 'Täytetty noin 65%'
        this.progressBulls = '•••'
        this.cssClass = 'text-orange'
      }
      else if(completedPercentage >= 75 && completedPercentage < 90) {
        this.title = 'Täytetty noin 80%'
        this.progressBulls = '••••'
        this.cssClass = 'text-green'
      }
      else if(completedPercentage >= 90) {
        this.title = 'Täytetty yli 90%'
        this.progressBulls = '•••••'
        this.cssClass = 'text-green'
      }
    }
  }
</script>
