<template>
  <div id="app">
    <authenticator :hide-sign-up="true" variation="modal"></authenticator>
    <div class="login-header navbar-header" v-if="auth.authStatus !== 'authenticated' || !store.state.user.attributesLoaded">
      Visma <span class="text-green">Pay</span> - <small>Partner Portal</small>
    </div>
    <template v-if="auth.authStatus === 'authenticated' && store.state.user.attributesLoaded">
      <MainPage />
    </template>
  </div>
</template>

<script setup>
import { watch } from 'vue'
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-vue'
import MainPage from './components/MainPage.vue'
import '@aws-amplify/ui-vue/styles.css'
import { useStore } from 'vuex'
import { I18n } from 'aws-amplify/utils'

I18n.setLanguage('fi')

I18n.putVocabularies({
  fi: {
    Email: 'Sähköposti',
    Password: 'Salasana',
    Code: 'Varmennuskoodi',
    Changing: 'Vaihdetaan',
    'Code *': 'Varmennuskoodi',
    Confirming: 'Vahvistetaan',
    'Enter your email': 'Sähköposti',
    'Enter your Email': 'Sähköposti',
    'Sign in': 'Kirjaudu',
    'Signing in': 'Kirjaudutaan sisään',
    'Enter your Password': 'Salasana',
    'Forgot your password?': 'Unohditko salasanasi?',
    'Reset password': 'Palauta salasana',
    'Reset Password': 'Palauta salasana',
    'Reset your password': 'Palauta salasana',
    'Reset your Password': 'Palauta salasana',
    'Back to Sign In': 'Takaisin',
    'Send code': 'Lähetä koodi',
    'Resend Code': 'Lähetä varmennuskoodi uudelleen',
    'Verification code': 'Varmennuskoodi',
    'Enter code': 'Varmennuskoodi',
    'New password': 'Uusi salasana',
    'New Password': 'Uusi salasana',
    'Confirm password': 'Vahvista salasana',
    'Confirm Password': 'Vahvista salasana',
    'Please confirm your Password': 'Vahvista salasana',
    'Enter your new password': 'Uusi salasana',
    'Password must have at least 8 characters': 'Salasanan tulee sisältää vähintään 8 merkkiä',
    'Your passwords must match': 'Salasanojen tulee olla samat',
    'Username cannot be empty': 'Sähköposti ei voi olla tyhjä',
    'Password cannot be empty': 'Salasana ei voi olla tyhjä',
    'User does not exist.': 'Virheellinen sähköposti tai salasana',
    'Incorrect username or password.': 'Virheellinen sähköposti tai salasana',
    'Username/client id combination not found.': 'Virheellinen sähköposti tai salasana',
    'Change Password': 'Vaihda salasana',
    'Change': 'Vaihda',
    'Password attempts exceeded': 'Liikaa kirjautumisyrityksiä',
    'Attempt limit exceeded, please try after some time.': 'Virheellisten yritysten määrä ylitetty. Ole hyvä ja yritä myöhemmin uudelleen.',
    'Submit': 'Lähetä',
    'User password cannot be reset in the current state.': 'Käyttäjän salasanaa ei voida palauttaa',
    'User is disabled.': 'Käyttäjä deaktivoitu',
    'Provided password cannot be used for security reasons.': 'Salasana ei ole tarpeeksi turvallinen',
    'Password does not conform to policy: Password not long enough': 'Salasana on liian lyhyt',
    'Password does not conform to policy: Password must have uppercase characters': "Salasanan tulee sisältää isoja kirjaimia",
    'Password does not conform to policy: Password must have numeric characters': "Salasanan tulee sisältää numeroita",
    'Invalid verification code provided, please try again.': 'Virheellinen varmennuskoodi',
    "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6": 'Salasana on liian lyhyt',
    "2 validation errors detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6; Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$": 'Salasana on liian lyhyt'
  }
});

const store = useStore()
const auth = useAuthenticator()

watch(auth,  (newAuth, oldAuth) => {
  switch(newAuth.authStatus) {
    case 'authenticated':
        store.dispatch('getAttributes');
        break;
    case 'unauthenticated':
        store.dispatch('removeAttributes');
        break;
  }
})

</script>
