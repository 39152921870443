<template>
  <transition name="slide-down-fade">
  <div class="modal" v-show="showModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" @click="closeModal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="col-md">
            <h1 class="modal-title">Luo partner -tunnus</h1>
          </div>
          <form role="form" @submit="createPartner">
            <div class="col-md">
              <div class="form-group required">
                <label for="partnerCode">Partnerkoodi (Y-tunnus)</label>
                <input type="text" class="form-control" v-model="partnerCode" name="partnerCode" placeholder="12345678" />
              </div>
            </div>
            <div class="col-md">
              <div class="form-group required">
                <label for="email">Sähköposti</label>
                <input type="text" class="form-control" v-model="email" name="email" placeholder="Sähköposti" />
              </div>
            </div>
            <div class="col-md">
              <div class="form-group">
                <label for="channelId">Sallitut kanava ID:t (jos useampi, erottele pilkulla)</label>
                <input type="text" class="form-control" v-model="channelIds" name="channelId" placeholder="Kanavan id.Kanavan nimi, esim: 123.Kanava Oy">
              </div>
            </div>
            <div class="col-md">
              <div class="form-group required">
                <label class="col-md">Paketit</label>
                <div v-for="pricePackage of pricePackages" v-bind:key="pricePackage" class="checkbox w-210">
                  <input 
                    type="checkbox" 
                    v-model="selectedPricePackages" 
                    :value="pricePackage" 
                    :id="pricePackage"
                  > 
                  <label class="fs-90" :for="pricePackage">{{pricePackage}}</label>
                </div>
              </div>
            </div>
            <div class="col-md mt-10">
              <button 
                type="submit" 
                :class="[inProgress === true ? 'disabled': '', 'btn w-100 btn-primary']">
                  Luo tunnus
              </button>
            </div>
            <div class="col-md">
              <transition name="slide-fade">
                <div v-if="errorMsg !== ''" class="alert alert-sm alert-danger alert-dismissible" role="alert">
                  <strong>Virhe!</strong> {{ errorMsg }}
                </div>
              </transition>
              <transition name="slide-fade">
                <div v-if="createdEmail !== ''" class="alert alert-sm alert-success alert-dismissible" role="alert">
                  <strong>Partnerkoodi:</strong> {{ createdPartnerCode}}<br> 
                  <strong>Sähköposti:</strong> {{ createdEmail }}<br> 
                  <strong>Salasana:</strong> {{ createdPassword }}
                </div>
              </transition>
            </div>
            <div class="col-md mt-10" v-if="createdUsers.length > 0">
              <a href="#" @click.prevent="showNowCreated = !showNowCreated">Näytä äskettäin luodut</a>
              <div v-if="showNowCreated">
                <ul>
                  <li v-for="createdUser of createdUsers" v-bind:key="createdUser.user">
                    <strong>Partnerkoodi:</strong> {{createdUser.partnerCode}}<br> 
                    <strong>Sähköposti:</strong> {{ createdUser.email }}<br> 
                    <strong>Salasana:</strong> {{ createdUser.password }}
                  </li>
                </ul>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  </transition>
</template>
<script>
import { post } from 'aws-amplify/api';

export default {
  name: 'CreatePartner',
  data() {
    return {
      inProgress: false,
      pricePackages: [
        'express',
        'express-0',
        'express-noinvoices',
        'express-0-25-noinvoices',
        'express-35-25',
        'express-35-28',
        'express-35-28-0',
        'express-35-28-0-r0',
        'power',
        'power-noinvoices',
        'power-0',
        'power-0-banks-39-cards-35-19',
        'power-20-14-1900',
        'power-35-18-0',
        'power-35-19-1900',
        'power-35-19-3900-noinvoices',
        'power-35-19-9900',
        'power-cards-18-088',
        'power-channel-35-19-5900',
        'power-channel-35-19-4900',
        'power-poplatek',
        'power-visma-real-estate'
      ],
      selectedPricePackages: [
        'express',
        'power'
      ],
      channelIds: '',
      email: '',
      partnerCode: '',
      createdEmail: '',
      createdPassword: '',
      createdpartnerCode: '',
      errorMsg: '',
      createdUsers: [],
      showNowCreated: false
    }
  },
  computed: {
    showModal() {
      return this.$store.state.showModal['createPartner']  === true;
    }
  },
  methods: {
    getPricePackages() {
      return  [
        'power',
        'express'
      ]
    },
    closeModal() {
      this.$store.state.showModal['createPartner'] = false;
    },
    async createPartner(event) {
      event.preventDefault();
      this.inProgress = true;
      this.errorMsg = '';
      this.createdEmail = '';

       try {
        let channelIds = [];

        if(this.channelIds !== '')
        {
          channelIds = this.channelIds.split(',').map(c => c.includes('.') ? c : Number(c));
        }

        const createPartnerPost = post({
          apiName: 'partnerportalapi',
          path: '/partners',
          options: {
            body: {
                email: this.email,
                partnerCode: this.partnerCode,
                pricePackages: this.selectedPricePackages,
                channelIds: channelIds
            }
          }
        });

        const { body } = await createPartnerPost.response;
        const response = await body.json();

        this.createdEmail = response.email;
        this.createdPassword = response.password;
        this.createdPartnerCode = response.partnerCode;
        this.createdUsers.push(response);
       }
      catch(e)
      {
        this.errorMsg = e.message ? e.message : e;
      } 

      this.inProgress = false;
    }
  }
}
</script>