<template>
  <div>
    <h1><span class="vismaicon vismaicon-note disabled"></span> Aloita sopimus</h1>
    <div class="panel">
      <div class="panel-body">
        <form role="form" @submit="initBoarding">
          <div class="row">
            <div class="col-md">
              <div class="form-group required">
                <label for="vatNumber">Y-tunnus</label>
                <input type="text" class="form-control" name="vatNumber" v-model="vatNumber" placeholder="1234567-8" />
              </div>
            </div>
            <div class="col-md">
              <div class="form-group required">
                <label for="pricePackage">Paketti</label>
                <select name="pricePackage" v-model="pricePackage" class="form-control">
                  <option v-for="(item, index) in pricePackages" :key="index" :value="item">{{ item.charAt(0).toUpperCase() + item.slice(1) }}</option>
                </select>
              </div>
            </div>
            <div class="col-md">
              <div class="form-group">
                <label for="companyName">Yrityksen nimi</label>
                <input type="text" class="form-control"  name="companyName" v-model="companyName" />
              </div>
            </div>
            <div class="col-md">
              <div class="form-group">
                <label for="email">Sähköposti</label>
                <input type="text" class="form-control"  name="email" v-model="email" />
              </div>
            </div>
            <div class="col-md" v-if="channelIds.length !== 0">
              <div class="form-group">
                <label for="channelId">Kanavan ID</label>
                <select name="channelId" v-model="channelId" class="form-control" >
                  <option value="">Ei liitetä</option>
                  <option v-for="(item, index) in channelIds" :key="index" :value="item">{{ parseChannelName(item) }}</option>
                </select>
              </div>
            </div>
            <div class="col-md" v-if="discountCodes.length !== 0">
              <div class="form-group">
                <label for="discountCodes">Alennuskoodi</label>
                <select name="discountCodes" v-model="discountCode" class="form-control" >
                  <option value="">Ei alennusta</option>
                  <option v-for="(item, index) in discountCodes" :key="index" :value="item">{{ parseDiscountCodeName(item) }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-10">
              <div class="form-group">
                <label for="prefillFormOption" class="label-checkbox">Näytä muut kentät</label>
                <input type="checkbox" id="prefillFormOption" v-model="prefillFormOption" @change="initPrefill" class="input-lg">
              </div>
            </div>
            <div class="col-xs-2">
              <transition name="slide-up">
                <button v-if="!usePrefilledData"
                  type="submit" 
                  :class="[getMainSubmitClass(), 'mt-12 btn btn-lg btn-primary float-right btn-block']">
                    Aloita sopimus
                </button>
              </transition>
            </div>
          </div>
        </form>
        <transition name="slide-down">
          <PrefillBoardingForm v-if="usePrefilledData" @pPrefillForm="processPrefillForm($event)" ref="prefillForm" />
        </transition>
        <transition name="slide-fade">
          <div v-if="errors !== ''" class="alert alert-danger" role="alert">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="closeAlert('errors')"></button>
            <strong>Virhe sopimuksen luonnissa!</strong><br>
            <span class="keep-line-breaks">{{ errors }}</span>
          </div>
        </transition>
        <transition name="slide-fade">
          <div v-if="signupUrl !== ''" class="alert alert-sm alert-success alert-dismissible" role="alert">
            <button type="button" class="close" data-dismiss="alert" aria-label="Close" @click="closeAlert('signupUrl')"></button>
            <strong>Sopimus luotu onnistuneesti!</strong> <a class="text-success" @click="copyToClipboard" href="#">Kopioi linkki leikepöydälle</a><br>
            <small>{{ signupUrl }}</small>
            <input class="hidden" type="text" name="boarding-url" id="boarding-url" :value="signupUrl">
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { urls } from '../config';
import PrefillBoardingForm from './PrefillBoardingForm.vue'

const translateErrors = {
  keys: {
    '.vatNumber': 'Y-Tunnus',
    '.pricePackage': 'Paketti',
    '.formData.companyName': 'Yrityksen nimi',
    '.formData.customerRepresenterEmail': 'Sähköposti',
    '.formData.merchantEmail': 'Sähköposti',
    '.formData.supportPhone': 'Asiakaspalvelun puhelin',
    '.formData.bankIban': 'IBAN tilinumero tilityksiä varten',
    '.formData.companyPhone': 'Yrityksen puhelinnumero',
    '.formData.companyBillingEmail': 'Sähköpostilaskun osoite',
    '.formData.channelId': 'Maksukanavan ID',
    '.formData.supportEmail': 'Asiakaspalvelun sähköposti',
    '.formData.customerRepresenterPhone': 'Puhelinnumero',
    '.formData.websiteUrlTest': 'Testisivuston osoite',
    '.channelId': 'Kanavan ID'
  },
  errors: {
    'Invalid format': 'Virheellinen muoto',
    'Email is not valid': 'Virheellinen sähköpostiosoite'
  },
  key(key)
  {
    return this.keys[key] ? this.keys[key] : key;
  },
  error(value)
  {
    return this.errors[value] ? this.errors[value] : value;
  }
}

export default {
  name: 'StartBoarding',
  components: {
    PrefillBoardingForm
  },
  data() {  
    return {
      vatNumber: '',
      companyName: '',
      email: '',
      pricePackage: '',
      pricePackages: [],
      channelId: '',
      channelIds: [],
      discountCodes: [],
      discountCode: '',
      inProgress: false,
      partnerCode: '',
      errors: '',
      signupUrl: '',
      prefillFormOption: '',
      prefilledFormData: {},
      usePrefilledData: false,
    }
  },
  created: async function() {
    try {
      this.pricePackages = JSON.parse(this.$store.state.user.attributes['custom:allowedPricePackages']);
      this.pricePackage = this.pricePackages[0];
    }
    catch(e){
      console.log('pricePackages', e);
    }

    try {
      this.channelIds = JSON.parse(this.$store.state.user.attributes['custom:allowedChannelIds']);
    }
    catch(e) {
      console.log('channelIds', e);
    }
    
    try {
      this.discountCodes = JSON.parse(this.$store.state.user.attributes['custom:discountCodes']);
    }
    catch(e) {
      console.log('discountCodes', e);
    }
    
    this.partnerCode = this.$store.state.user.attributes['custom:partnerCode'];
  },
  methods: {
    closeAlert: function(field) {
      this[field] = '';
    },
    initBoarding: async function(event) {
      event.preventDefault();
      this.inProgress = true;
      this.errors = '';
      this.signupUrl = '';

      const initBoardingData = {
        vatNumber: this.vatNumber,
        pricePackage: this.pricePackage,
        partnerCode: this.partnerCode,
        formData: this.prefilledFormData,
        partnerMetaData: {
          email: this.$store.state.user.attributes.email
        },
      }

      if(this.discountCode)
        initBoardingData.discountCode = this.discountCode;

      if(this.companyName)
        initBoardingData.formData.companyName = this.companyName;

      if(this.email)
      {
        initBoardingData.formData.merchantEmail = this.email;
        initBoardingData.formData.customerRepresenterEmail = this.email;
      }

      if(this.channelId)
      {
        let channelId, channelName = '';

        if(typeof this.channelId === 'string')
        {
          const matches = this.channelId.match(/(\d*).(.*)/);
          if(Array.isArray(matches) && matches.length === 3)
          {
            channelId = Number(matches[1]);
            channelName = matches[2];
          }
        }
        else
        {
          channelId = Number(this.channelId);
        }

        initBoardingData.formData.channelId = channelId;
        initBoardingData.formData.channelName = channelName;
      }
        
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(initBoardingData)
      }
      try {
        const response = await fetch(urls.boardingUrl, requestOptions);
        const data = await response.json();

        if(data.validationErrorMessages)
        {
          const errorKeys = [];
          const errorsForPrefillForm = [];
          data.validationErrorMessages.forEach((error) => {
            const translatedKey = translateErrors.key(error.field);
            if(!errorKeys.includes(translatedKey))
            {
              this.errors += translatedKey + ': ' + translateErrors.error(error.error) + "\n";
              errorKeys.push(translatedKey)
              errorsForPrefillForm.push({
                field: error.field,
                message: translateErrors.error(error.error)
              })
            }
          });

          // send errors to prefillform if active
          if (this.prefillFormOption) {
            this.$refs.prefillForm.showErrorMessagesOnFields(errorsForPrefillForm);
          }
        }
        else if(data.id)
        {
          if (this.prefillFormOption) { // prefill form is active
            this.$refs.prefillForm.clearForm();
            this.$refs.prefillForm.inProgress = false;
          }
          this.vatNumber = '';
          this.discountCode = '';
          this.email = '';
          this.channelId = '';
          this.companyName = '';
          this.prefilledFormData = {};

          this.signupUrl = urls.signUpUrl + data.id;
          this.emitter.emit('newBoardingId', data.id);
        }
        else
        {
          this.errors = 'Odottamaton virhe';
        }
      }
      catch(e)
      {
        console.log(e);
        this.errors = 'Odottamaton virhe';
      }
      if (this.prefillFormOption) { // prefill form is active
        this.$refs.prefillForm.inProgress = false;
      }
      this.inProgress = false;
    },
    copyToClipboard: function(event) {
      event.preventDefault();
      navigator.clipboard.writeText(this.signupUrl);
    },
    parseDiscountCodeName: function(code) {
      const name = code.split('_')[0];
      const pieces = name.split('-');
      return name + ' (' + pieces[1] + ' kk, -' + pieces[2] + '%)';
    },
    parseChannelName: function(value) {
      return typeof value === 'string' ? value.replace('.' , ' - ') : value;
    },
    processPrefillForm: function(data) {
      this.prefilledFormData = data.data;

      this.initBoarding(data.evt);
    },
    initPrefill: function(event) {
      event.preventDefault();
      if (this.prefillFormOption) {
        this.inProgress = true;
        this.usePrefilledData = true;
      } else {
        this.inProgress = false;
        this.usePrefilledData = false;
        this.prefilledFormData = {};
      }
    },
    getMainSubmitClass: function() {
      if (this.inProgress === true) {
        return 'disabled';
      } else {
        return '';
      }
    }
  }
}
</script>