<template>
  <div>
    <form @submit="submitPrefillForm">
      <div class="prefillform">
        <hr/>
        <h2 class="mt-15">Yrityksen tiedot</h2>   
        <div class="row">
          <div class="col-md">
            <div class="form-group">
              <label for="companyPhone">Yrityksen puhelinnumero</label>
              <input type="text" class="form-control" v-model="companyPhone" @change="inputChanged" name="companyPhone" id="companyPhone" />
              <p v-if="errors.companyPhone" class="text-red">{{ errors.companyPhone }}</p>
            </div>
          </div>
          <div class="col-md">
            <div class="form-group">
              <label for="companyAddressStreet">Katuosoite</label>
              <input type="text" class="form-control" v-model="companyAddressStreet" @change="inputChanged" name="companyAddressStreet" id="companyAddressStreet" />
              <p v-if="errors.companyAddressStreet" class="text-red">{{ errors.companyAddressStreet }}</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md">
            <div class="form-group">
              <label for="companyPostal">Postinumero</label>
              <input type="text" class="form-control" v-model="companyPostal" @change="inputChanged" name="companyPostal" id="companyPostal" />
              <p v-if="errors.companyPostal" class="text-red">{{ errors.companyPostal }}</p>
            </div>
          </div>
          <div class="col-md">
            <div class="form-group">
              <label for="companyCity">Paikkakunta</label>
              <input type="text" class="form-control" v-model="companyCity" @change="inputChanged" name="companyCity" id="companyCity" />
              <p v-if="errors.companyCity" class="text-red">{{ errors.companyCity }}</p>
            </div>
          </div>
        </div>

        <h2 class="mt-15">Palvelun tiedot</h2>
        <div class="row">
          <div class="col-md">
            <div class="form-group">
              <label for="businessDescription">Mitä tuotteita/palveluita yrityksesi myy?</label>
              <input type="text" class="form-control" v-model="businessDescription" @change="inputChanged" name="businessDescription" id="businessDescription" />
              <p v-if="errors.businessDescription" class="text-red">{{ errors.businessDescription }}</p>
            </div>
          </div>
          <div class="col-md">
            <div class="form-group">
              <label for="marketingName">Markkinointinimi</label>
              <input type="text" class="form-control" v-model="marketingName" @change="inputChanged" name="marketingName" id="marketingName"  />
              <p v-if="errors.marketingName" class="text-red">{{ errors.marketingName }}</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md">
            <div class="form-group">
              <label for="websiteUrl">Sivuston osoite</label>
              <input type="text" class="form-control" v-model="websiteUrl" @change="inputChanged" name="websiteUrl" id="websiteUrl" />
              <p v-if="errors.websiteUrl" class="text-red">{{ errors.websiteUrl }}</p>
            </div>
          </div>
          <div class="col-md">
            <div class="form-group">
              <label for="websiteUrlTest">Testisivuston osoite</label>
              <input type="text" class="form-control" v-model="websiteUrlTest" @change="inputChanged" name="websiteUrlTest" id="websiteUrlTest"  />
              <p v-if="errors.websiteUrlTest" class="text-red">{{ errors.websiteUrlTest }}</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md">
            <div class="form-group">
              <label for="supportEmail">Asiakaspalvelun sähköposti</label>
              <input type="text" class="form-control" v-model="supportEmail" @change="inputChanged" name="supportEmail" id="supportEmail" />
              <p v-if="errors.supportEmail" class="text-red">{{ errors.supportEmail }}</p>
            </div>
          </div>
          <div class="col-md">
            <div class="form-group">
              <label for="supportPhone">Asiakaspalvelun puhelin</label>
              <input type="text" class="form-control" v-model="supportPhone" @change="inputChanged" name="supportPhone" id="supportPhone" />
              <p v-if="errors.supportPhone" class="text-red">{{ errors.supportPhone }}</p>
            </div>
          </div>
        </div>
        
        <h2 class="mt-15">Yhteyshenkilö</h2>
        <div class="row">
          <div class="col-md">
            <div class="form-group">
              <label for="customerRepresenterFirstname">Etunimi</label>
              <input type="text" class="form-control" v-model="customerRepresenterFirstname" @change="inputChanged" name="customerRepresenterFirstname" id="customerRepresenterFirstname"  />
              <p v-if="errors.customerRepresenterFirstname" class="text-red">{{ errors.customerRepresenterFirstname }}</p>
            </div>
          </div>
          <div class="col-md">
            <div class="form-group">
              <label for="customerRepresenterLastname">Sukunimi</label>
              <input type="text" class="form-control" v-model="customerRepresenterLastname" @change="inputChanged" name="customerRepresenterLastname" id="customerRepresenterLastname" />
              <p v-if="errors.customerRepresenterLastname" class="text-red">{{ errors.customerRepresenterLastname }}</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="customerRepresenterPhone">Puhelinnumero</label>
              <input type="text" class="form-control" v-model="customerRepresenterPhone" @change="inputChanged" name="customerRepresenterPhone" id="customerRepresenterPhone"  />
              <p v-if="errors.customerRepresenterPhone" class="text-red">{{ errors.customerRepresenterPhone }}</p>
            </div>
          </div>
        </div>
  
        <h2 class="mt-15">Valitse maksutapojen lisäpalvelut</h2>
        <div class="form-group d-table">
          <label for="isRefundV2" class="label-checkbox">Kehittynyt maksupalautusten hallinta</label>
          <input type="checkbox" class="input-lg" v-model="isRefundV2" id="isRefundV2" name="isRefundV2" />
        </div>
        <div class="form-group d-table">
          <label for="isGrossPayout" class="label-checkbox">Bruttotilitys</label>
          <input type="checkbox" class="input-lg" v-model="isGrossPayout" id="isGrossPayout" name="isGrossPayout" />
        </div>
        
        <h2 class="mt-15">Lisätiedot</h2>
        <div class="form-group">
          <label for="additionalInfo">Lisätietoja hakemukselle</label>
          <textarea type="text" class="form-control" v-model="additionalInfo" name="additionalInfo" id="additionalInfo" />
        </div>
        <div class="row">
          <div class="col-xs-2 offset-xs-10">
            <div class="form-group">
              <button type="submit" :class="[inProgress === true ? 'disabled' : '', 'mt-12 btn btn-lg btn-primary float-right btn-block']">Aloita sopimus</button>
              <br>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
 export default {
    name: 'PrefillBoardingForm',
    data() {
      return {
        // Form fields
        companyPhone: '',
        companyAddressStreet: '',
        companyPostal: '',
        companyCity: '',
        businessDescription: '',
        marketingName: '',
        websiteUrl: '',
        websiteUrlTest: '',
        supportEmail: '',
        supportPhone: '',
        customerRepresenterFirstname: '',
        customerRepresenterLastname: '',
        customerRepresenterPhone: '',
        isRefundV2: false,
        isGrossPayout: false,
        companyBillingAddressStreet: '',
        companyBillingPostal: '',
        companyBillingCity: '',
        eInvoiceAddress: '',
        eInvoiceProcessor: '',
        companyBillingEmail: '',
        additionalInfo: '',
        // Internal 
        inProgress: false,
        errors: {},
      } 
    },
    computed: {

    },
    methods: {
      submitPrefillForm: function(event) {
        event.preventDefault();
        this.inProgress = true;

        // clear error messages
        this.errors = {};
        const errorFields = document.getElementsByClassName("has-error");
        if (errorFields) {
          Array.from(errorFields).forEach(field => {
            field.classList.remove('has-error');
          });
        }

        const dataObj = {};

        if(this.companyPhone) {
          dataObj.companyPhone = this.companyPhone;
        }
        
        if(this.companyAddressStreet) {
          dataObj.companyAddressStreet = this.companyAddressStreet;
        }
        
        if(this.companyPostal) {
          dataObj.companyPostal = this.companyPostal;
        }
        
        if(this.companyCity) {
          dataObj.companyCity = this.companyCity;
        }
        
        if(this.businessDescription) {
          dataObj.businessDescription = this.businessDescription;
        }
        
        if(this.marketingName) {
          dataObj.marketingName = this.marketingName;
        }
        
        if(this.websiteUrl) {
          dataObj.websiteUrl = this.websiteUrl;
        }
        
        if(this.websiteUrlTest) {
          dataObj.websiteUrlTest = this.websiteUrlTest;
        }
        
        if(this.supportEmail) {
          dataObj.supportEmail = this.supportEmail;
        }
        
        if(this.supportPhone) {
          dataObj.supportPhone = this.supportPhone;
        }
        
        if(this.customerRepresenterFirstname) {
          dataObj.customerRepresenterFirstname = this.customerRepresenterFirstname;
        }
        
        if(this.customerRepresenterLastname) {
          dataObj.customerRepresenterLastname = this.customerRepresenterLastname;
        }
        
        if(this.customerRepresenterPhone) {
          dataObj.customerRepresenterPhone = this.customerRepresenterPhone;
        }
        
        if(this.isRefundV2) {
          dataObj.isRefundV2 = this.isRefundV2;
        }
        
        if(this.isGrossPayout) {
          dataObj.isGrossPayout = this.isGrossPayout;
        }
        
        if(this.companyBillingAddressStreet) {
          dataObj.companyBillingAddressStreet = this.companyBillingAddressStreet;
        }
        
        if(this.companyBillingPostal) {
          dataObj.companyBillingPostal = this.companyBillingPostal;
        }
        
        if(this.companyBillingCity) {
          dataObj.companyBillingCity = this.companyBillingCity;
        }
        
        if(this.eInvoiceAddress) {
          dataObj.eInvoiceAddress = this.eInvoiceAddress;
        }
        
        if(this.eInvoiceProcessor) {
          dataObj.eInvoiceProcessor = this.eInvoiceProcessor;
        }
        
        if(this.companyBillingEmail) {
          dataObj.companyBillingEmail = this.companyBillingEmail;
        }
        
        if(this.additionalInfo) {
          dataObj.additionalInfo = this.additionalInfo;
        }

        this.$emit('pPrefillForm', {
          data: dataObj, 
          evt: event
        });
      },
      clearForm: function() {
        this.inProgress = false;
        this.errors = {};

        this.companyPhone = '';
        this.companyName = '';
        this.companyAddressStreet = '';
        this.companyPostal = '';
        this.companyCity = '';
        this.businessDescription = '';
        this.marketingName = '';
        this.websiteUrl = '';
        this.websiteUrlTest = '';
        this.supportEmail = '';
        this.supportPhone = '';
        this.customerRepresenterFirstname = '';
        this.customerRepresenterLastname = '';
        this.customerRepresenterPhone = '';
        this.isRefundV2 = false;
        this.isGrossPayout = false;
        this.companyBillingAddressStreet = '';
        this.companyBillingPostal = '';
        this.companyBillingCity = '';
        this.eInvoiceAddress = '';
        this.eInvoiceProcessor = '';
        this.companyBillingEmail = '';
        this.isPep = false;
        this.isRca = false;
        this.additionalInfo = '';
      },
      showInfo: function(itemName, event) {
        event.preventDefault();
        this.$refs[itemName].toggle();
      },
      showErrorMessagesOnFields: function(errors) {
        errors.forEach(error => {
          const fields = error.field.split('.');
          if (fields.length == 3) {
            this.errors[fields[2]] = error.message;
            // Add error class to input field
            const fieldWithErr = document.getElementById(fields[2]);
            if (fieldWithErr) { // Make sure that field does exist in this form
              fieldWithErr.parentElement.classList.add('has-error');
            }
          }
        });
      },
      inputChanged: function(event) {
        if (this.errors[event.target.id]) {
          delete this.errors[event.target.id]; // Delete error message
          event.target.parentElement.classList.remove('has-error'); // Remove error class
        }
      }
    }
  }
</script>
